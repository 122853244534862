@import './colors';
@import './const';

html {
  ::-moz-focus-inner {
    border: 0;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $dark-gray;
  color: $dark-gray;

  &.CartOpened {
    overflow: hidden;
  }
}

ul {
  list-style-type: none;
}


h1, h2, h3, h4, h5, h6 {
  font-family: $font-martin;
  text-transform: uppercase;
  line-height: normal;
}

h2 {
  font-size: 30px;
}

.BackLink {
  padding: 0.375rem 0;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &.BackLinkBottom {
    color: $gray;

    &:hover {
      color: $dark-gray;
    }
  }
}

.ButtonRight {
  text-align: right;
}

.BlockExplanation {
  color: $gray;
}

.BlockExtraText {
  margin-top: 15px;
}

.Block {
  box-shadow: 0 2px 20px 0 $shadow-dark;
  background-color: $white;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  padding: 20px;
}

.DetailsDivider {
  border-color: $gray-3;
  margin: 40px 0;
}

@media (max-width: 575.98px) {
  .container > .row {
    margin-left: 0;
    margin-right: 0;

    .CheckOutColumn {
      padding: 0;
    }
  }
}

@media (max-width: 991.98px) {
  .CheckOutColumn {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.alert {
  padding: 10px 20px;
  margin-top: 1rem;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
