@import '~scss/colors';

.LoadingSpinner {
  width: 64px;
  height: 64px;

  > div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid $primary;
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $primary transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  &.PageLoader {
    margin: auto;
    padding: 40px 0;
    height: 144px;
  }

  &.HasText {
    padding-bottom: 10px;
    height: 114px;
  }
}

.LoaderText {
  text-align: center;
  color: $gray;
  padding-bottom: 40px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
