@import '~scss/const';
@import '~scss/colors';

.LargeCart {
  box-shadow: 0 2px 20px 0 $shadow-dark;
  background-color: $white;
  padding: 20px;
  position: relative;

  .CartInner.Loading, .CartButtons.Loading {
    opacity: 0.3;
  }

  .CartItemMobile {
    display: none;
  }

  .CartItemDesktop {
    display: flex;
  }

  .CartItemPriceTotal {
    font-size: 18px;
    font-weight: bold;
    text-align: right;
  }

  .CartTableHeader {
    border-bottom: 1px solid $gray-3;
    padding: 10px 0;
  }

  .CartTableHeader, .CartTableLabel {
    font-weight: bold;
    color: $gray;
  }

  .CartTotalTop {
    padding-bottom: 0;
  }

  .CartTotalBottom {
    padding-top: 0;
  }

  .CartEmpty {
    padding: 50px 0;
    text-align: center;
    color: $gray;
  }

}

.TicketAmount.form-control {
  background-color: $gray-3;
  margin: 0 10px;
  width: 70px;
  padding: 6px 12px;
  height: 50px;
  text-align: center;
  border: 0;
}

@media (max-width: 767.98px) {
  .LargeCart {
    .CartTableHeader {
      display: none;
    }

    .CartItemMobile {
      display: block;
      margin: 0;

      > .row {
        margin-bottom: 15px;
      }

      .CartItemPriceTotal {
        text-align: left;
      }

      .TicketAmount.form-control {
        margin: 0;
      }
    }

    .CartItemDesktop {
      display: none;
    }

    .LargeCartButtons {
      margin: 0;

      > div {
        padding: 0;
      }

      .BackLinkBottom {
        font-size: 14px;

        svg {
          height: 16px;
          width: 16px;
        }
      }

      .btn.btn-primary {
        font-size: 20px;
      }
    }
  }
}
