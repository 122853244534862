@import '~scss/const';
@import '~scss/colors';

.TicketItem {
  padding: 20px 0;
  border-bottom: 1px solid $gray-3;

  align-items: center;
  justify-content: center;

  .TicketItemName {
    font-weight: bold;
  }

  .TicketItemEventDate {
    color: $gray;
  }

  .TicketItemButton {
    text-align: right;
  }

  .TicketItemPersonalizeButton {
    font-weight: bold;

    .TicketItemPersonalizeButtonIcon {
      margin-left: 5px;

      path {
        color: $gray;
      }
    }

    &:hover {
      text-decoration: none;

      .TicketItemPersonalizeButtonIcon path {
        color: $primary;
      }
    }

    &:disabled {
      background: none;
    }

    .TicketLoader {
      width: 24px;
      margin-left: 5px;
      display: inline-block;
    }
  }

  .NotLinked {
    color: $gray;
  }
}
