@import '~scss/const';
@import '~scss/colors';

.PaymentDue {
  margin-top: 15px;
  color: $gray;
}

.Buttons {
  margin-top: 15px;
}
