@import '~scss/const';
@import '~scss/colors';

.Alert {
  display: flex;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  box-shadow: 0 2px 20px 0 $shadow-dark;
  background-color: $primary-light;
  margin-bottom: 10px;
  padding: 20px;
}

.AlertIcon {
  float: left;
  margin-right: 10px;
}

.Alert .AlertIcon svg path {
  fill: $primary;
}

.Alert strong {
  color: $primary;
}
