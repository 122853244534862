@import '~scss/const';
@import '~scss/colors';

$paymentOptionLineHeight: 32px;
$labelFontSize: 16px;
$checkboxDimensions: 16px;
$checkboxTop: calc(($paymentOptionLineHeight - $checkboxDimensions) / 2);

.PaymentOptions {
  box-shadow: 0 2px 10px 0 $shadow-light;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  border: solid 1px $gray-2;
  margin-bottom: 10px;
  overflow: auto;
}

.PaymentOption {
  &:hover, &:focus {
    .PaymentOptionHeader {
      background: $primary-light;
      border-color: $primary;
    }

    outline: none;


    .PaymentOptionRadio {
      .icon {
        border-color: $primary;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }

  &.Selected {
    border: 1px solid $primary;

    &:hover, &:focus {
      .PaymentOptionHeader {
        border-color: transparent;
      }
    }

    .PaymentOptionBody {
      display: block;
    }

    .PaymentOptionRadio {
      .icon:after {
        content: '';
        position: absolute;
        border-radius: 50%;
        top: 2px;
        left: 2px;
        height: 10px;
        width: 10px;
        background-color: $primary;
      }
    }
  }

  .PaymentOptionRadio {
    padding-left: $checkboxDimensions + 8px;
    display: block;
    position: relative;
    margin-bottom: 8px;
    font-size: 16px;
    color: $dark-gray;

    .icon {
      content: '';
      position: absolute;
      border-radius: 50%;
      height: $checkboxDimensions;
      width: $checkboxDimensions;
      top: $checkboxTop;
      border: 1px solid $gray;
      box-sizing: border-box;
      left: 0;
    }
  }

  .PaymentOptionLogo {
    width: $paymentOptionLineHeight;
    height: $paymentOptionLineHeight;
    vertical-align: middle;
    margin-left: 5px;

    svg {
      width: $paymentOptionLineHeight;
      height: auto;
    }
  }

  .PaymentOptionHeader {
    display: flex;
    padding: 10px 20px;
    border: solid 1px transparent;
    border-bottom-color: $gray-2;

    .PaymentOptionSelect {
      width: 30px;
    }

    .PaymentOptionName {
      flex: 1;
      line-height: $paymentOptionLineHeight;
    }
  }

  .PaymentOptionBody {
    display: none;
    padding-left: 50px;
    border-bottom: solid 1px $gray-2;

    .PaymentOptionBodyContents {
      padding: 15px 20px 20px 0;
    }
  }
}
