@import '~scss/const';
@import '~scss/colors';

.CheckoutFooter {
  padding-top: 24px;
  color: $gray;

  .SafePaymentItem {
    display: inline-block;
    padding: 10px;
  }

  .SafePaymentIcon {
    fill: $gray;
  }

  .PrivacyNotice {
    margin-top: 10px;
    text-align: center;
  }

  .container {
    max-width: 740px;
  }

  .PaymentMethods {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid $border;
    text-align: center;

    .PaymentMethodFooter {
      display: inline-block;
      padding: 0 5px;
      max-height: 26px;

      svg {
        height: 24px;
        width: auto;
      }
    }

    .PaymentMethodsText {
      font-size: 12px;
    }

    .PaymentMethodVendor {
      svg {
        height: 20px;
      }
    }

  }
}
