@import '~scss/colors';

$labelLineHeight: 1.5;
$labelFontSize: 16px;
$checkboxDimensions: 16px;
$checkboxTop: calc((($labelLineHeight * $labelFontSize) - $checkboxDimensions) / 2);

.RadioGroup {
  .FieldLabel {
    margin-bottom: 8px;
  }

  .RadioOption {
    padding-left: $checkboxDimensions + 8px;
    display: block;
    position: relative;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: $labelLineHeight;
    color: $dark-gray;
  }

  input {
    position: absolute;
    opacity: 0;

    + .icon {
      content: '';
      position: absolute;
      border-radius: 50%;
      height: $checkboxDimensions;
      width: $checkboxDimensions;
      top: $checkboxTop;
      border: 1px solid $gray;
      box-sizing: border-box;
      left: 0;
    }

    &:checked + .icon:after {
      content: '';
      position: absolute;
      border-radius: 50%;
      top: 2px;
      left: 2px;
      height: 10px;
      width: 10px;
      background-color: $primary;
    }

    &:active + .icon,
    &:focus + .icon {
      border-color: $primary;
    }
  }

  &.Horizontal .RadioOption {
    display: inline-block;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}
