@import '~scss/const';
@import '~scss/colors';

.DetailsBlock {
  .BlockExplanation {
    margin-bottom: 15px;
  }

  h4 {
    font-family: $font-aeonik;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .CartBody {
    padding: 0;
  }
}

.CheckOutInfo {
  font-size: 14px;

  .btn-link {
    font-size: 14px;
    padding: 0;

    svg {
      height: 14px;
      width: 14px;
    }
  }
}
