@import '~scss/colors.scss';

.Events .Main {
  padding-top: 30px;
}

.NoResults {
  text-align: center;
  width: 100%;
  padding: 120px 0;
  color: #9b9b9b;
  font-style: italic;
}
