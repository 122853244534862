@font-face {
  font-family: 'Aeonik';
  src: url('../fonts/Aeonik-BoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../fonts/Aeonik-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../fonts/Aeonik-RegularItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../fonts/Aeonik-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}


@font-face {
  font-family: 'Aeonik';
  src: url('../fonts/Aeonik-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../fonts/Aeonik-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Martin';
  src: url('../fonts/MARTIN-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  size-adjust: 100%;
}

body {
  font-family: 'Aeonik', "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

html {
  font-size: 15px;
}