$dark-gray: #2a2822;
$gray: #9b9b9b;
$gray-2: #d0d0d0;
$gray-3: #f4f4f4;
$disabled-gray: #e7e7e7;

$green: #8fb85e;
$yellow: #fdfd87;
$red: #d0021b;

$error: #c15757;

$background: #f4f4f4;
$primary: #9659e1;
$primary-light: #f4eefc;
$white: #ffffff;

$shadow-light: rgba(0, 0, 0, 0.1);
$shadow-dark: rgba(0, 0, 0, 0.2);

$border: #dfdfdf;
