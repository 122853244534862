@import '~scss/const';
@import '~scss/colors';

.OrderItem {
  padding: 20px 0;
  border-bottom: 1px solid $gray-3;

  align-items: center;
  justify-content: center;

  .OrderItemEventName {
    font-weight: bold;
  }

  .OrderItemButton {
    text-align: right;
  }

  .OrderItemEventDate {
    color: $gray;
  }

  .OrderItemViewOrderButton {
    font-weight: bold;
  }
}
