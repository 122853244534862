@import '~scss/const';
@import '~scss/colors';

.CartWrapper, .StickyCart {
  z-index: 1000;
}

.StickyCart.Active {
  .CartFill {
    height: 100px;
  }
}

.StickyCart {
  transform: translateZ(0px);

  .CartBody {
    background: /* Shadow covers */
            linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */
            radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
    background: /* Shadow covers */
            linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */
            radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
    max-height: calc(100vh - 650px);
    padding: 0 20px;
  }

  .CartItem:first-child {
    padding-top: 10px;
  }

  .CartVoucherInput .Input {
    margin-bottom: 0;
  }
}

.CartWrapper {
  //position: absolute;
  max-height: calc(100vh - 130px);
}

.SafePayment {
  text-align: center;
  padding-top: 16px;
  color: $gray;

  .SafePaymentIcon {
    fill: $gray;
  }
}

.Cart {
  box-shadow: 0 2px 20px 0 $shadow-dark;
  background-color: $white;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  overflow: hidden;
  padding: 0 !important;
  max-height: inherit;

  .CartHeader {
    img {
      width: 100%;
    }

    h2 {
      padding: 15px 20px 0 20px;
    }
  }
}

.Cart, .SmallCart, .MobileCart, .LargeCart {
  .LoadingSpinner {
    position: absolute;
    left: 50%;
    margin-left: -32px;
    top: 50%;
    margin-top: -32px;
  }
}

.Cart, .SmallCart {
  .CartItem {
    .row {
      margin: 0;

      > div {
        padding: 0;
      }
    }
  }
}

.CartVoucherInput, .CartVoucherAdd {
  border-top: 1px solid $gray-3;
  padding: 20px 0;
  min-height: 82px;

  .AddVoucherLink {
    padding: 0;
  }
}

.CartVoucherInput.row {
  margin-left: 0;
  margin-right: 0;

  > div {
    padding: 0;
  }

  button, input {
    height: 41px;
  }

  .CartVoucherInputCloseIcon {
    fill: $gray;

    &:hover {
      fill: $dark-gray;
    }
  }
}

.CartBody {
  padding: 20px;
  position: relative;
  overflow: auto;
  min-height: 60px;
}

.CartFooter {
  padding: 0 20px 20px 20px;

  .CartTotal {
    .CartTotalTextTotal, .CartTotalTextDescription {
      display: inline;
    }

    .CartTotalTextDescription {
      padding-left: 6px;
    }
  }
}

.CartInner.Loading, .CartButtons.Loading, .MobileCart .Loading, .CartFooter.Loading {
  opacity: 0.3;
}

.CartEmpty {
  font-size: 18px;
  line-height: 1.67;
  color: #9b9b9b;
  padding: 20px 0;
}

.CartItem {
  padding: 20px 0;
  border-bottom: 1px solid $gray-3;

  .CartItemQuantity, .CartItemNameTicket, .CartItemPrice {
    font-weight: bold;
  }

  .CartItemPrice {
    text-align: right;
  }

  .CartItemNameVariety {
    color: $gray;
  }

  .CartItemQuantity {
    .TicketAmount.form-control {
      margin: 0;
    }
  }

  .OldPrice {
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    color: #9b9b9b;
    text-decoration: line-through;
    margin-left: 10px;
    font-weight: normal;
  }

  .DiscountBadge {
    background-color: #fdfd87;
    color: #d0021b;
    font-size: 14px;
    font-family: $font-martin;
    text-transform: uppercase;
    display: inline-block;
    padding: 3px 6px;
    margin-top: 1px;
    line-height: 1;
  }
}

.VoucherRow {
  color: $gray;

  .CartItemQuantity, .CartItemNameTicket, .CartItemPrice {
    font-weight: normal;
  }

  .VoucherCode svg {
    width: 15px;
  }

  .RemoveButton svg {
    width: 18px;
  }

  .RemoveButton {
    padding: 0 3px;
    line-height: 1;
  }

  .CartRemoveMessage {
    color: $dark-gray;
  }
}

.CartTotalDiscount {
  border-bottom: 1px solid $gray-3;
}

.CartTotal, .CartTotalDiscount {
  padding: 20px 0;

  .CartTotalTextTotal, .CartTotalAmount, .CartTotalDiscountText, .CartTotalDiscountAmount {
    font-weight: bold;
    font-size: 18px;
  }

  .CartTotalTextDescription {
    color: $gray;
    font-size: 16px;
    font-weight: normal;
  }

  .CartTotalAmount, .CartTotalDiscountAmount {
    text-align: right;
  }
}

.LargeCart {
  .RemoveButton {
    .RemoveButtonIcon {
      fill: $gray;
    }

    &:hover .RemoveButtonIcon {
      fill: $primary;
    }
  }

  .CartVoucherInput, .CartVoucherAdd {
    border-top: none;
    padding: 0;
  }

  .CartItemQuantity {
    font-weight: normal;
    text-align: center;
  }

  .CartItemDesktop .DiscountBadge {
    margin-left: 10px;
    font-weight: normal;
  }
}

.MobileCartBar {
  box-shadow: 0 1px 20px 0 $shadow-light, 0 1px 4px 0 rgba(0, 0, 0, 0);
  background-color: $white;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 2px solid $primary;
  z-index: 2000;
  height: 110px;
  overflow: hidden;
  cursor: pointer;

  .CartEmpty, .CartTotal {
    padding: 0;
  }

  .CartTotalTextTotal {
    color: $gray;
    font-weight: normal;
  }

  .CartBody {
    padding: 20px 0;
  }

}

.MobileCartFull {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 2000;
  background-color: $white;
  overflow: auto;

  .CartHeader {
    img {
      width: 100%;
    }
  }

  .CloseButton.btn-light {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 100% !important;
    background: $white;
    padding: 0px;
    height: 36px;
    width: 36px;
    cursor: pointer;
    z-index: 2001;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

@media (max-width: 991.98px) {
  .CartWrapper {
    display: none;
  }

  body {
    padding-bottom: 110px;
  }
}

@media (min-width: 992px) {
  .MobileCart {
    display: none;
  }
  .EventTickets {
    min-height: 150px;
  }
}

@media (max-width: 575.98px) {
  .MobileCartBar .CartBody {
    padding: 20px 15px;
  }
}
