@import './colors';
@import './const';

body#error-page {
  background-color: $background;


  #error-wrapper {
    width: 90%;
    max-width: 550px;

    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, .2);
    background-color: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    overflow: hidden;
    padding: 0 !important;
    margin: auto;

    #error-header {
      border-bottom: 1px solid $background;
      overflow: hidden;
      margin: 0 10px;

      #error-logo {
        float: left;

        img {
          width: 75px;
          height: 75px;
        }
      }

      #error-code {
        float: right;
        padding: 15px;
        font-size: 30px;
        font-family: $font-martin;
      }
    }

    #error-contents {
      padding: 20px;

      #error-title {
        margin-bottom: 0;
      }

      #error-subtitle {
        color: $gray;
      }
    }

    #error-link-back {
      margin-top: 10px;
    }
  }
}
