@import '~scss/const';
@import '~scss/colors';

.ConfirmDialog {
  border-radius: $radius;
  box-shadow: 0 2px 20px 0 $shadow-dark;
  background-color: $white;
  padding: 20px;
  min-width: 400px;
  border: none !important;

  right: 0;
  left: initial !important;

  .popover-body {
    padding: 0;
  }

  .ConfirmDialogText {
    margin-bottom: 10px;
  }

  .ConfirmButtons {
    text-align: center;
  }

  &.TicketDownloadConfirmDialog {
    min-width: 450px;
  }
}

.ConfirmDialogOverlay {
  width: 100vw;
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 1;

  animation: fadein .5s;
  -moz-animation: fadein .5s;
  /* Firefox */
  -webkit-animation: fadein .5s;
  /* Safari and Chrome */
  -o-animation: fadein .5s;

  .ConfirmDialogOverlayBackground {
    background: white;
    opacity: 0.8;
    width: 100%;
    height: 100%;
  }
}
