@import '~scss/colors';

.AccountOrdersOrder .Main {
  padding-top: 30px;
}

.OrderEventName {
  margin-bottom: 0;
}

.OrderEventDetails {
  font-weight: bold;
  margin-bottom: 10px;

  .OrderEventDetailsIcon {
    margin-right: 5px;

    path {
      fill: $primary;
    }
  }
}
